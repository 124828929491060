@import "core/index.scss";
.page.campaign-detail {
	padding: 0 0 4rem;

	.detail-content {
		@include mediaMin {
			display: flex;
		}
	}

		.content-left {
			margin: 0 0 2rem;

			@include mediaMin {
				width: 40%;
				margin: 0 2rem 0 0;
			}
		}

		.content-right {
			flex: 1 0 0;
		}

		.content-description {
			font-size: 1.4rem;
			line-height: 1.3em;
			color: $color-text-light;
		}

		.content-title {
			@include font-bold;
			font-size: 2rem;
			line-height: 1.5em;
		}

		.content-imagewrap {
			padding-bottom: 44%;
			position: relative;
		}

		.content-image {
			position: absolute;
			width: 100%;
			height: 100%;
			display: block;
			background-color: $color-bg;
			
			@include mediaMin {
				border-radius: $radius-general;
			}
		}

		.content-description {
			margin-top: 1.5rem;
		}

		.content-date {
			color: $color-text-lighter;
			font-size: 1.2rem;
			text-align: right;
			margin-top: 2rem;
			border-top: .1rem solid $color-gray-light;
			padding-top: 2rem;
		}
		
		.content-controls {
			margin-top: 2rem;
		}
}